import clsx from 'clsx';
import React from 'react';

import { ProductCard } from './ProductCard';
import { Container, EContainerVariant } from '../../sections/Container';

type TProductCardsProps = {
  cards: React.ComponentProps<typeof ProductCard>[];
};

export const ProductCards: React.FC<TProductCardsProps> = props => {
  const { cards } = props;

  return (
    <Container variant={EContainerVariant.Full}>
      <div
        className={clsx(
          'grid gap-2 tablet:gap-4',
          'tablet:grid-rows-[repeat(2,_1fr)]',
          'tablet:grid-cols-2 desktop:grid-cols-3',
        )}
      >
        {cards.map((card, index) => (
          <div
            key={index}
            className={
              card.links?.length ? 'tablet:col-auto tablet:row-span-2' : ''
            }
          >
            <ProductCard key={index} {...card} />
          </div>
        ))}
      </div>
    </Container>
  );
};
