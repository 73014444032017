import clsx from 'clsx';
import Link, { LinkProps } from 'next/link';
import React from 'react';

import { IconChevronLeft } from '../../../icons';

type TLink = {
  text: string;
  linkProps: LinkProps;
};

type TLinksBlockProps = {
  links: TLink[];
};

export const LinksBlock: React.FC<TLinksBlockProps> = ({ links }) => {
  if (!links.length) {
    return null;
  }

  return (
    <div className='mt-8 hidden pt-8 tablet:block'>
      {links.map((link, index) => (
        <Link
          key={index}
          className={clsx(
            'flex flex-row items-center justify-between gap-2 pt-[17px] text-base font-medium leading-loose hover:opacity-80',
            {
              'border-b border-[rgba(67,68,70,0.35)] pb-4':
                index !== links.length - 1,
            },
          )}
          {...link.linkProps}
        >
          <span className='text-interface-50'>{link.text}</span>
          <IconChevronLeft
            width={16}
            height={16}
            className='shrink-0 rotate-180 text-interface-500 rtl:rotate-0'
          />
        </Link>
      ))}
    </div>
  );
};
