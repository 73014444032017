import Link, { LinkProps } from 'next/link';
import React from 'react';

import { Icon, ESimpleIconSize } from '../../../atoms/Icon';
import { ImageWithPlaceholder } from '../../../atoms/ImageWithPlaceholder';
import { ETitlePartsSize, TitlePart } from '../../../atoms/TitlePart';
import { IconChevronLeft } from '../../../icons';

import type { ImageProps } from 'next/image';

type TTitleBlockProps = {
  title: React.JSX.Element | string | null;
  tag?: string;
  image?: ImageProps | null;
  icon?: ImageProps | null;
  cardLink?: LinkProps | null;
  isTabletBreakpoint?: boolean;
};

export const TitleBlock: React.FC<TTitleBlockProps> = ({
  title,
  tag,
  image,
  icon,
  cardLink,
  isTabletBreakpoint,
}) => {
  const content = (
    <div className='flex flex-row items-center justify-between'>
      {image ? (
        <div className='flex-1'>
          <ImageWithPlaceholder {...image} className='h-8 max-w-full' isDark />
        </div>
      ) : null}

      {!image && (icon || title) ? (
        <div className='flex items-center gap-4'>
          {icon ? <Icon icon={icon} size={ESimpleIconSize.L} /> : null}
          {title ? (
            <TitlePart
              size={ETitlePartsSize.S}
              className='text-interface-50 tablet:text-3xl tablet:font-semibold tablet:leading-loose'
            >
              {title}
            </TitlePart>
          ) : null}
        </div>
      ) : null}

      {cardLink ? (
        <IconChevronLeft
          width={16}
          height={16}
          className='shrink-0 rotate-180 text-interface-500 tablet:hidden rtl:rotate-0'
        />
      ) : null}

      {tag ? (
        <div className='hidden shrink-0 rounded-5xl border border-surface-50/30 px-[15px] pb-1.5 pt-[5px] text-sm font-medium leading-loose text-interface-50 tablet:block'>
          {tag}
        </div>
      ) : null}
    </div>
  );

  if (cardLink && isTabletBreakpoint) {
    return (
      <Link {...cardLink} className='hover:opacity-80'>
        {content}
      </Link>
    );
  }

  return content;
};
