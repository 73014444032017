import {
  ProductCards,
  ProductCard,
  RichText,
  toImageProps,
  isRichTextContentEmpty,
  toLinkProps,
} from '@front/shared/ds';
import { useRouter } from 'next/router';
import React, { useMemo, useCallback } from 'react';

import type { Section } from '@shared/master-types';

export type TProductCardsConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'product-cards' }
>;

export const ProductCardsConnected: React.FC<
  TProductCardsConnectedProps
> = props => {
  const { cards = [], cardsWithLinks = [] } = props;
  const router = useRouter();
  const currentLocale = String(router.query.locale || '');

  const processBlock = useCallback(
    (
      card: Required<TProductCardsConnectedProps>['cardsWithLinks'][number],
    ): React.ComponentProps<typeof ProductCard> => {
      const {
        image,
        icon,
        title,
        description,
        tag,
        link: cardLink,
        links,
      } = card;

      const preparedData: React.ComponentProps<typeof ProductCard> = {
        image: toImageProps(image),
        icon: toImageProps(icon),
        title:
          title && !isRichTextContentEmpty(title) ? (
            <RichText content={title} />
          ) : null,
        description:
          description && !isRichTextContentEmpty(description) ? (
            <RichText content={description} />
          ) : null,
        tag,
        cardLink: cardLink?.length
          ? toLinkProps(currentLocale, cardLink[0])
          : null,
        links: (links || []).map(link => ({
          text: link.text,
          linkProps: toLinkProps(currentLocale, link),
        })),
      };

      return preparedData;
    },
    [currentLocale],
  );

  const blocks: React.ComponentProps<typeof ProductCard>[] = useMemo(() => {
    const preparedCardsWithLinks = cardsWithLinks.map(processBlock);
    const preparedCards = cards.map(processBlock);
    return [...preparedCardsWithLinks, ...preparedCards];
  }, [cards, cardsWithLinks, processBlock]);

  return <ProductCards cards={blocks} />;
};

export default ProductCardsConnected;
