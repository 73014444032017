import { baseTailwindConfig } from '@front/shared';
import clsx from 'clsx';
import Link, { LinkProps } from 'next/link';
import React from 'react';
import { useMedia } from 'react-use';

import { TitleBlock, LinksBlock } from './components';

import type { ImageProps } from 'next/image';

type TLink = {
  text: string;
  linkProps: LinkProps;
};

type TProductCardProps = {
  title: React.JSX.Element | string | null;
  description: React.JSX.Element | string | null;
  tag?: string;
  image?: ImageProps | null;
  icon?: ImageProps | null;
  cardLink?: LinkProps | null;
  links: TLink[];
};

export const ProductCard: React.FC<TProductCardProps> = ({
  title,
  description,
  tag,
  image,
  icon,
  cardLink,
  links,
}) => {
  const isTabletBreakpoint = useMedia(
    `(min-width: ${baseTailwindConfig.screens.tablet})`,
    true,
  );

  const content = (
    <div className='flex h-full flex-col justify-between rounded-3xl bg-interface-1000/70 p-6 tablet:p-8'>
      <div
        className={clsx(
          'flex flex-col justify-between gap-4 tablet:min-h-[140px]',
          {
            'flex-1': !links.length,
          },
        )}
      >
        <TitleBlock
          title={title}
          tag={tag}
          image={image}
          icon={icon}
          cardLink={cardLink}
          isTabletBreakpoint={isTabletBreakpoint}
        />

        {description && isTabletBreakpoint ? (
          <div className='hidden pr-2 text-base font-medium leading-loose text-[rgba(148,152,159,1)] prose-a:text-interface-50 tablet:block [&_a:hover]:opacity-80'>
            {description}
          </div>
        ) : null}
      </div>

      {isTabletBreakpoint ? <LinksBlock links={links} /> : null}
    </div>
  );

  if (cardLink && !isTabletBreakpoint) {
    return (
      <Link {...cardLink} className='hover:opacity-80'>
        {content}
      </Link>
    );
  }

  return content;
};
